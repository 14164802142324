<template>
    <div class="edu-frame">
        <edu-nav-bar title="选择报读时段" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto;">
                <div class="step-tips">请选择报读班级的时段</div>
                <div class="step-tips-detail">请先选择报读时段</div>
                <hr class="split-line"/>
                <!--暑秋班级-->
                <div class="term-frame" v-if="term.term == 1">
                    <div class="term-frame-btn" v-if="showHanShu == 1">
                        <van-button square type="default" @click="toXzzy('shuqi')">暑期班</van-button>
                    </div>

                    <div class="term-frame-btn" v-if="showChunQiu == 1">
                        <van-button square type="default" @click="toXzzy('qiuji')">秋季班</van-button>
                    </div>
                </div>
                <!--暑秋班级-->
                <!--寒春班级-->
                <div class="term-frame" v-if="term.term == 2">
                    <div class="term-frame-btn"  v-if="showHanShu == 1">
                        <van-button square type="default" @click="toXzzy('hanjia')">寒假班</van-button>
                    </div>

                    <div class="term-frame-btn" v-if="showChunQiu == 1">
                        <van-button square type="default" @click="toXzzy('chunji')">春季班</van-button>
                    </div>
                </div>
                <!--寒春班级-->
            </div>
        </div>
    </div>
</template>
<script>
    import {Button} from 'vant';
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "@/api/Tools";
    import EduConstants from "@/api/EduConstants";

    export default {
        components: {
            EduNavBar,
            VanButton: Button,
        },
        data() {
            return {
                show: false,
                term: {},
                showChunQiu: 0,
                showHanShu: 0,
                cust: {}
            }
        },
        name: 'login',
        methods: {
            toXzzy(termTime) {
                // TODO 选择报读时段

                // TODO 判断是否允许报读本班
                this.cust.termTime = termTime
                BaomingApi.checkIsOnlyOldClass(this.cust).then(response => {
                    if (response.res == 1) {
                        // 报读老班 选择课程
                        this.$router.push({name: 'xzkc', query: { term: this.term.term, termtime: termTime, termid: this.term.termId }})
                    } else {
                        if (this.cust.loginType == EduConstants.LOGINTYPE_ACTIVITY) {
                            // 如果是活动课程
                            this.$router.push({name: 'xzkc', query: { term: this.term.term, termtime: termTime, termid: this.term.termId }})
                        } else {
                            // 报读新班 选择专业
                            this.$router.push({name: 'xzzy', query: { term: this.term.term, termtime: termTime, termid: this.term.termId }})
                        }

                    }
                })


            }
        },
        mounted() {
            this.cust = Tools.getCurCust();
            BaomingApi.selectTerm(this.cust).then(response => {
                this.term = response.res.term
                this.showChunQiu = response.res.showChunQiu
                this.showHanShu = response.res.showHanShu
            })
        }
    }
</script>
<style scoped>
    .term-frame {
        width: 60%;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .split-line {
        width: 90%;
        border: 0;
        height: 1px;
        background-color: #dddddd;
    }

    .term-frame-btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
